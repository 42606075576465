import NotifierService from '../Notifier/NotifierService'
import ApiService from '../Api/ApiService'
import StatusService from '../Status/StatusService'


/**
 * Private stored defaultOptions for the module.
 */
let options = {
  uri: {
    publish: 'job_offers/[jobId]/placement', // Endpoint that the PublisherService publishes at
    unpublish: 'job_offers/[jobId]/placement/delete', // Endpoint that the PublisherService unpublishes at
  },
  payload: {}, // Pack any additional payload here to make a request to the endpoint work
  selector: 'data-job-action', // The button(s) that will be listened to
  selectorErrors: '[data-job-errors]', // The container that has the error-messages
  messages: {
    success: {
      publish: 'Die Anzeige wurde erfolgreich veröffentlicht!',
      unpublish: 'Die Anzeige wurde offline genommen!',
    },
    error: {
      publish: 'Die Anzeige konnte nicht veröffentlicht werden!',
      unpublish: 'Die Anzeige konnte nicht offline genommen werden!',
    }
  }
}


/**
 * Singleton storage.
 */
let instance = null


/**
 * Singleton export.
 */
export default (userOptions) => {
  if (instance !== null) {
    return instance
  }
  return instance = new PublisherService (userOptions)
}


class PublisherService {
  /**
   * The PublisherService offers to un/publish a joboffer.
   * @param {object} userOptions - The Configuration for this service
   * @return {PublisherService}
   */
  constructor (userOptions) {
    options = {...options, ...userOptions}
    this.state = null
    return this
  }


  /**
   * Initializes the Module.
   * @return {PublisherService}
   */
  init () {
    // Get elements that call the publish action
    this.elements = document.querySelectorAll(`[${options.selector}]`)
    if (this.elements.length < 1) {
      return this
    }
    this.initElements()
    this.state = 'initialized'
    console.log('INIT:', this)
    return this
  }


  /**
   * Registers click listeners to all elements.
   */
  initElements () {
    this.elements.forEach((element) => {
      switch(element.getAttribute(options.selector)) {
        case 'publish':
          element.addEventListener('click', (event) => {
            console.log('Publishing joboffer...')
            this.changeState('publish')
          })
          break
        case 'unpublish':
          element.addEventListener('click', (event) => {
            console.log('Unpublishing joboffer...')
            this.changeState('unpublish')
          })
          break
      }
    })
  }


  /**
   * Resets the service by uninitializing and reinitializing it.
   */
  reset () {
    this.elements.forEach((element) => {
      
      if (typeof element !== 'undefined' && element !== null) {
        const elementClone = element.cloneNode(true)
        element.parentNode.replaceChild(elementClone, element)
      }
    })
    this.elements = null
    this.init()
    this.enableButtons() // TODO: Is this required?
  }


  /**
   * Publishes or unpublishes a joboffer depending on passed method.
   * @param {string} method - Method of state change requires 'publish' | 'unpublish'
   * @return {Promise} - Resolves to boolean for success/failure
   */
  changeState (method) {
    if (method !== 'publish' && method !== 'unpublish') {
      console.error('The method for changeState is not valid!');
      return Promise.reject(false)
    }
    this.state = 'waiting'
    this.hideErrors()
    this.disableButtons()
    return ApiService()
      .post(options.uri[method], options.payload)
      .then((success) => {
        this.state = 'success'
        console.log('action', method, this.state)
        StatusService()
          .watchStatus(method === 'unpublish')
          .then((success) => {
            console.log('StatusService received success', success)
            if (success) {
              console.log ('PUB: start reset...')
              this.reset()
              NotifierService().createNotification(options.messages.success[method], 'success')
            }
            return true
          })
      })
      .catch((error) => {
        this.state = 'error'
        console.log('action', method, this.state)
        if (error.response.data.hasOwnProperty('errors')) {
         this.showErrors(method, error.response.data.errors)
        }
        this.enableButtons()
        return false
      })
  }


  /**
   * Disables all publish buttons.
   */
  disableButtons () {
    this.elements.forEach((element) => {
      element.setAttribute('disabled', true)
    })
  }


  /**
   * Enables all publish buttons.
   */
  enableButtons() {
    this.elements.forEach((element) => {
      element.removeAttribute('disabled')
    })
  }


  /**
   * Renders the errors to the view.
   * @param {String} method - Method of state change requires 'publish' | 'unpublish'
   * @param {Array} errors - An Array with error strings
   */
  showErrors (method, errors) {
    if (typeof errors !== 'object' || errors.length < 1) {
      return false
    }
    const errorContainer = document.querySelector(options.selectorErrors)
    const errorList = document.createElement('ul')
    const errorHead = document.createElement('h3')
    errorHead.classList.add('mb-2')
    errorHead.innerText = options.messages.error[method]
    errors.forEach((errorText) => {
      const errorItem = document.createElement('li')
      errorItem.innerText = errorText
      errorList.appendChild(errorItem)
    })
    errorContainer.innerHTML = ''
    errorContainer.appendChild(errorHead)
    errorContainer.appendChild(errorList)
    errorContainer.hidden = false
  }


  /**
   * Hides the errors in view.
   */
  hideErrors () {
    const errorContainer = document.querySelector(options.selectorErrors)
    errorContainer.hidden = true
  }
}
