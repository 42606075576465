const defaultOptions = {
  offsetX: 0,
  offsetY: 0,
  template: `
    <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header"></div>
      <div class="toast-body"></div>
      <button class="btn-close" data-toast-btn="dismiss" aria-label="Close">
        <svg><use xlink:href="#icon-close" /></svg>
      </button>
    </div>
  `,
  selectHeader: '.toast-header',
  selectBody: '.toast-body',
  selectButton: '.btn-close',
  useIcons: true,
  svgIconMap: {
    default: 'icon-info',
    info: 'icon-info',
    success: 'icon-check',
    warning: 'icon-exclamation',
    danger: 'icon-close',
  }
};


/**
 * Creates an SVG Element that uses a symbol of a SVG Sprite.
 * @param {String} iconId - Id of the SVG inside SVG sprite
 */
const createSvgIcon = (iconId) => {
  if (typeof iconId === 'undefined') {
    return null;
  }
  const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    useElement = document.createElementNS('http://www.w3.org/2000/svg', 'use');
  useElement.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#${iconId}`);
  svgElement.appendChild(useElement);
  return svgElement;
};


export default class Notification {
  /**
   * Construct the Notification.
   */
  constructor (userOptions) {
    this.options = {...defaultOptions, ...userOptions};
    this.element = null;
    return this;
  }

  /**
   * Initializes the Module.
   */
  init () {
    const parser = new DOMParser(),
      template = parser.parseFromString(this.options.template, 'text/html');
    this.element = template.body.firstChild;
    this.element
      .querySelector(this.options.selectButton)
      .addEventListener('click', (event) => {
        this.hide();
      });
    return this;
  }


  /**
   * Sets the type for the Notification.
   * @param {String} type - The type is either: 'success', 'warning', 'danger', 'info'
   */
  setType (type) {
    type = typeof type === 'undefined' ? 'default' : type;
    switch(type) {
      case 'success':
        this.element.classList.add('toast-success');
        if (this.options.useIcons) {
          this.element
            .querySelector(this.options.selectHeader)
            .appendChild(createSvgIcon(this.options.svgIconMap.success));
        }
        break;
      case 'warning':
        this.element.classList.add('toast-warning');
        if (this.options.useIcons) {
          this.element
            .querySelector(this.options.selectHeader)
            .appendChild(createSvgIcon(this.options.svgIconMap.warning));
        }
        break;
      case 'danger':
        this.element.classList.add('toast-danger');
        if (this.options.useIcons) {
          this.element
            .querySelector(this.options.selectHeader)
            .appendChild(createSvgIcon(this.options.svgIconMap.danger));
        }
        break;
      case 'info':
          this.element.classList.add('toast-info');
          if (this.options.useIcons) {
            this.element
              .querySelector(this.options.selectHeader)
              .appendChild(createSvgIcon(this.options.svgIconMap.info));
          }
        break;
      default:
        this.element.classList.add('toast-default');
        if (this.options.useIcons) {
          this.element
            .querySelector(this.options.selectHeader)
            .appendChild(createSvgIcon(this.options.svgIconMap.default));
        }
        break;
    }
    return this;
  }


  /**
   * Sets animation classes to show a notification.
   */
  show () {
    this.element.classList.remove('toast-hide');
    this.element.classList.add('toast-show');
  }


  /**
   * Sets animation classes to hide a notification.
   */
  hide () {
    // this.element.classList.remove('toast-show');
    this.element.classList.add('toast-hide');
    setTimeout(() => {
      this.destroy();
    }, 500);
  }


  /**
   * Sets the Notification content.
   */
  setContent (content) {
    this.element.querySelector(this.options.selectBody).innerHTML = content;
    return this;
  }


  /**
   * Destroy this element.
   */
  destroy () {
    if (this.element !== null) {
      if (this.element.parentElement !== null) {
        this.element.parentElement.removeChild(this.element);
      }
    }
  }
}