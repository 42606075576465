const defaultOptions = {
  selector: '[data-textarea-autosize]'
}


export const loadTextareaAutosize = (userOptions) => {
  const collection = [],
    options = {...defaultOptions, ...userOptions};
  document
    .querySelectorAll(options.selector)
    .forEach((textareaElement) => {
      collection.push(new TextareaAutosize(options).init(textareaElement));
    });
  return true;
};


export default class TextareaAutosize {
  constructor (userOptions) {
    this.options = {...defaultOptions, ...userOptions};
    this.element = null;
    return this;
  }

  
  /**
   * Initializes the Module.
   * @param {object} - A HTMLElement that the autosizer should be attached to
   * @return {TextareaAutosize|null}
   */
  init (element) {
    if (typeof element !== 'object') {
      console.error('Could not initialize TextareaAutosizer. It requires a HTMLElement passed!');
      return null;
    }
    this.element = element;
    // Setup listeners
    this.element.addEventListener('keydown', (event) => { this.autosize(event.target) });
    this.element.addEventListener('cut', (event) => { this.autosize(event.target) });
    this.element.addEventListener('paste', (event) => { this.autosize(event.target) });
    this.element.addEventListener('change', (event) => { this.autosize(event.target) });
    // Setup initial detection
    this.autosize(this.element);
    return this;
  }

  
  /**
   * Autosizes the height of a textarea element.
   * @param element The HTMLElement that should get autosized
   */
  autosize (element) {
    setTimeout(() => {
      const elementOffset = element.offsetHeight - element.clientHeight;
      const cachedScrollPosition = window.pageYOffset;
      element.style.height = 'auto';
      element.style.height = element.scrollHeight + elementOffset + 'px';
      window.scrollTo(0, cachedScrollPosition);
      // console.log('Autosize for ', element.clientHeight, element.scrollHeight, elementOffset);
    }, 0);
  }
}
