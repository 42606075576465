import { parseTemplate } from '../../helper/html'
import EditListTextItem from './editList-item-text'
import EditListDatetimeItem from './editList-item-datetime'

/**
 * Private storage for all occurences of this component.
 */
let ComponentStorage = []


/**
 * The Components default options.
 */
const defaultOptions = {
  text: {
    text: {
      addItem: 'Neuen Punkt hinzufügen',
    },
    datetime: {
      addItem: 'Neuen Zeitraum hinzufügen',
    }
  },
  addItemTemplate: `
    <div class="editList-add-item" data-edit-list-btn="addItem" tabindex="0">
      <svg><use xlink:href="#icon-plus" /></svg>
      <span>{{ addItem }}</span>
    </div>
  `,
  itemOptions: {},
}


/**
 * Traverses the DOM and maps all EditLists to each found element.
 * @param {object} userOptions - (optional) The user configuration object for each EditList (default: {})
 * @param {HTMLElement} context - (optional) The node on wich the dom traversion should be started (default: document)
 * @param {boolean} autoInit - (optional) Automatically runs init of each found component (default: true)
 * @return {array} - The component's complete unscoped collection
 */
export const loadEditLists = (userOptions = {}, context = document, autoInit = true) => {
  context.querySelectorAll('[data-edit-list]')
    .forEach((editListElement) => {
      const Component = new EditList(editListElement, userOptions)
      if (autoInit) {
        Component.init()
      }
    })
  return ComponentStorage
}


/**
 * Grabs and removes prerendered data from DOM within passed Element.
 * @param {object} parentElement - The parent HTMLElement that contains data-edit-list-item
 * @return {array} - A collection of items stored as strings.
 */
const getPrefetchedData = (parentElement) => {
  const itemData = [],
    itemElements = parentElement.querySelectorAll('[data-edit-list-item]')
  itemElements.forEach((itemElement) => {
    if (itemElement.value.length > 0) {
      itemData.push(itemElement.value)
    }
    parentElement.removeChild(itemElement)
  })
  return itemData
}


export default class EditList {
  constructor (element, userOptions) {
    if (typeof element !== 'object' || (element instanceof HTMLElement) !== true) {
      console.error('Passed element is not of type object or an instance of HTMLElement!', element)
    }
    this.options = {...defaultOptions, ...userOptions}
    this.element = element
    this.index = ComponentStorage.push(this) - 1
    this.identifier = `EditList_${this.index}`
    this.element.setAttribute('data-module-id', this.identifier)
    this.itemType = null
    this.items = []
    return this
  }

  /**
   * Initialize the Module.
   */
  init () {
    // Initialize Items
    this.initConfigs()
    this.initPrefetchedItems()
    // Init addButton
    const template = parseTemplate(this.options.addItemTemplate, this.options.text[this.itemType])[0]
    this.addButton = this.element.appendChild(template)
    this.addButton.addEventListener('click', event => {
      const Item = this.addItem()
      this.focusItem(Item.index)
    })
    this.addButton.addEventListener('keydown', event => event.key === 'Enter' || event.key === ' ' ? this.addItem() : null)
    console.log('INIT:', this)
    return this
  }


  /**
   * Initializes and overrides custom configs set by data attributes.
   */
  initConfigs () {
    // Override itemName by data attribute
    const itemName = this.element.getAttribute('data-edit-list-item-name')
    if (itemName !== null) {
      this.options.itemOptions = {...this.options.itemOptions, ...{ attrName: itemName }}
    }
    // Override itemType by data attribute
    this.itemType = this.element.getAttribute('data-edit-list-item-type') || 'text'
  }


  /**
   * Initializes prerendered items from DOM and builds EditListItems out of it.
   * It removes the prerendered items afterwards.
   * @return {boolean}
   */
  initPrefetchedItems () {
    const prefetchedItems = getPrefetchedData(this.element)
    if (prefetchedItems.length < 1) {
      return false
    }
    prefetchedItems.forEach((itemValue) => {
      const Item = this.addItem()
      Item.setValue(itemValue)
    })
    return true
  }


  /**
   * Adds a new input item to the list.
   * @param {number} position - The array position the item should be added to (default: null)
   * @return {EditListItem} - The freshly created list item
   */
  addItem (position = null) {
    let itemIndex = null,
      Item = null
    
    switch (this.itemType) {
      case 'text':
        Item = new EditListTextItem(this, this.options.itemOptions)
        break
      case 'datetime':
        Item = new EditListDatetimeItem(this, this.options.itemOptions)
        break
    }

    if (position === null) {
      // Push item to end
      itemIndex = this.items.push(Item) - 1
      Item.initBasicItem(itemIndex)
      this.element.insertBefore(Item.element, this.addButton)
    } else {
      // Push item between
      itemIndex = position
      this.items.splice(itemIndex, 0, Item)
      Item.initBasicItem(itemIndex)
      this.reIndex()
      if ((itemIndex + 1) <= (this.items.length - 1)) {
        this.element.insertBefore(Item.element, this.items[itemIndex + 1].element)
      } else {
        this.element.insertBefore(Item.element, this.addButton)
      }
    }
    this.element.dispatchEvent(new CustomEvent('item:add', { detail: { Item: Item}}))
    return Item
  }


  /**
   * Sets focus to an item.
   * @param {number} itemIndex - The index of the item
   * @return {EditListItem} - The focused item
   */
  focusItem (itemIndex) {
    if (itemIndex < 0 || itemIndex > (this.items.length - 1)) {
      return null
    }
    return this.items[itemIndex].focus()
  }


  /**
   * Removes an item from the list.
   * @param {EditListItem|number} Item - An EditListItem or an item index
   */
  removeItem (Item) {

    if (typeof Item === 'number') {
      Item = this.items[Item] || null // Override
    }
    this.items.splice(Item.index, 1)
    this.element.removeChild(Item.element)
    this.reIndex()
    this.element.dispatchEvent(new CustomEvent('item:remove'))
    return this
  }


  /**
   * Reindexes all items.
   */
  reIndex () {
    this.items
      .forEach((Item, newIndex) => {
        Item.setIndex(newIndex)
      })
      return this
  }


  /**
   * Cleans out the list by removing all empty items and trim its values.
   */
  removeEmptyItems () {
    let countItems = (this.items.length - 1)
    for(let i = countItems; i >= 0; i--) {
      // Remove whitespaces at beginning and end of string
      this.items[i].input.value = this.items[i].input.value.trim()
      // Remove zero length items
      if (this.items[i].input.value.length < 1) {
        this.removeItem(this.items[i]).reIndex()
      }
    }
  }
}
