import { parseHtml } from '../../helper/html';

const defaultOptions = {
  templates: {
    module: `<div class="text-templates"></div>`,
    item: `<div class="text-template-item"></div>`,
  }
};
let options = {};


export default class SelectListMulti {
  constructor (userOptions) {
    options = {...defaultOptions, ... userOptions};
    this.element = null;
    this.data = null;
    this.isValid = false;
    return this;
  }

  /**
   * Initializes the module.
   * @param {Array} data - The selection items as array
   * @param {TextTemplate} parent - The parent controller
   */
  init (data, parent) {
    if (data.constructor.name !== 'Array') {
      console.error(`Template data is not of type Array!`);
      return this;
    }
    if (data.length < 1) {
      console.warn(`Template data is empty!`);
      return this;
    }
    this.data = data;
    this.parent = parent;
    this.element = parseHtml(options.templates.module)[0];
    this.selected = [];
    this.initItems();
    return this;
  }


  /**
   * Initializes items.
   */
  initItems () {
    this.data.forEach((item, index) => {
      if (!item.hasOwnProperty('content')) {
        console.error('Given data object for SelectListItem requires a property of `content`!');
        return false;
      }
      const itemElement = parseHtml(options.templates.item)[0];
      itemElement.textContent = item.content;
      this.element.appendChild(itemElement);
      itemElement.setAttribute('data-index', index);
      itemElement.addEventListener('click', (event) => {
        this.selectItem(index);
      });
      itemElement.addEventListener('dblclick', (event) => {
        this.selectItem(index);
        this.parent.takeTemplates();
      });
    });
    return this;
  }

  /**
   * Selects an item by index.
   * @param {number} index - Index of the selected item
   * @return {SelectList}
   */
  selectItem(index) {
    if (!this.selected.includes(index)) {
      this.selected.push(index);
      this.element
        .querySelector(`[data-index="${index}"]`)
        .classList.add('active');
    } else {
      this.selected = this.selected.filter(selectedItem => selectedItem !== index);
      this.element
        .querySelector(`[data-index="${index}"]`)
        .classList.remove('active');
    }
    this.isValid = (this.selected.length > 0);
    this.parent.validate();
    return this;
  }
}