import { parseHtml, toNodeList } from '../../helper/html';
import ModalService from '../../services/Modal/ModalService';
import SelectList from './SelectList';
import SelectListMulti from './SelectListMulti';

const defaultOptions = {
  selector: '[data-text-template]',
  textTemplates: window.textTemplates,
};
let options = {};


export const loadTextTemplates = (userOptions, selector) => {
  const collection = [];
  options = {...defaultOptions, userOptions};
  selector = selector || options.selector;
  document
    .querySelectorAll(selector)
    .forEach((textTemplateElement) => {
      collection.push(new TextTemplate(textTemplateElement, options).init());
    });
    return collection;
};


export default class TextTemplate {
  /**
   * Constructor for the TextTemplate module.
   * @param {HTMLElement} element - The dom element for this module
   * @param {object} userOptions - The configuration for this module
   * @return {TextTemplate}
   */
  constructor (element, userOptions) {
    options = {...defaultOptions, userOptions};
    this.element = element;
    this.key = null;
    this.type = null;
    this.target = null;
    this.textTemplates = null;
    return this;
  }

  
  /**
   * Initializes the module.
   * @return {TextTemplate}
   */
  init () {
    // Grab data
    this.type = getType(this.element);
    this.key = this.element.getAttribute('data-text-template-key');
    this.target = this.element.querySelector(`${this.element.getAttribute('data-text-template-target')}`);
    this.textTemplates = getTemplates(this.key);
    // If there are no text templates for this key...
    if (this.textTemplates === null) {
      // ... remove button
      this.element
      .querySelectorAll('[data-text-template-btn="open"]')
      .forEach((btnElement) => {
        btnElement.parentElement.removeChild(btnElement);
      });
    } else {
      // Init buttons
      this.element
        .querySelectorAll('[data-text-template-btn="open"]')
        .forEach((btnElement) => {
          btnElement.addEventListener('click', (event) => {
            event.preventDefault();
            this.showTemplates();
          });
        });
    }
    console.log('INIT:', this);
    return this;
  }


  /**
   * Builds a modal with selection options and shows it to the user.
   */
  showTemplates () {
    let title = 'Bitte wähle eine Vorlage aus:';
    let modalBodyHtml = [];
    // Generate the SelectList by type
    switch (this.type) {

      case 'single':
        this.selectList = new SelectList().init(this.textTemplates, this);
        // Add notice on demand
        if (this.target.value.length > 0) {
          modalBodyHtml.push(parseHtml(`
            <div class="alert-outline-warning d-flex align-items-center mb-4">
              <svg class="mr-3"><use xlink:href="#icon-exclamation-triangle" /></svg>
              <p><strong>Achtung:</strong> Bei Übernahme einer neuen Vorlage wird der bisherige Inhalt überschrieben!</p>
            </div>
          `)[0]);
        }
        modalBodyHtml.push(this.selectList.element);
        break;

      case 'multiple':
        this.selectList = new SelectListMulti().init(this.textTemplates, this);
        title = 'Bitte wähle mindestens eine Vorlage aus:';
        modalBodyHtml.push(this.selectList.element);
        break;
    }

    // Generate the Modal
    ModalService()
      .reset()
      .setTitle(title)
      .setBody(toNodeList(modalBodyHtml))
      .setFooter(`
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Abbrechen</button>
        <button type="button" class="btn btn-primary" data-text-template-btn="select">Auswählen</button>
      `)
      .init()
      .show();
    
    // Disable take-template-button
    const selectBtn = document.querySelector('[data-text-template-btn="select"]');
    selectBtn.setAttribute('disabled', true);
    selectBtn.addEventListener('click', (event) => this.takeTemplates());
  }


  /**
   * Takes templates from selectList and injects them into the target.
   */
  takeTemplates () {
    ModalService().hide();
    switch(this.type) {
      case 'single':
        this.target.value = this.textTemplates[this.selectList.selected].content;
        this.target.dispatchEvent(new Event('change'));
        break;
      case 'multiple':
        const id = this.target.getAttribute('data-module-id');
        const EditList = window.editLists.filter(editlist => editlist.identifier === id)[0];
        EditList.removeEmptyItems();
        this.selectList.selected.forEach((selectedIndex) => {
          const ListItem = EditList.addItem();
          ListItem.setValue(this.textTemplates[selectedIndex].content);
        });
        EditList.element.dispatchEvent(new CustomEvent('item:change'));
        break;
    }
  }

  
  /**
   * Validates the selection and changes states.
   * @return {TextTemplate}
   */
  validate () {
    if (this.selectList.isValid) {
      const selectBtn = document.querySelector('[data-text-template-btn="select"]');
      selectBtn.removeAttribute('disabled', true);
    } else {
      const selectBtn = document.querySelector('[data-text-template-btn="select"]');
      selectBtn.setAttribute('disabled', true);
    }
    return this;
  }
}

function getType (element) {
  const type = element.getAttribute('data-text-template'),
    allowedTypes = ['single', 'multiple'];
  return allowedTypes.includes(type) ? type : 'single';
}

function getTemplates (key) {
  if (!options.textTemplates.hasOwnProperty(key)) {
    console.warn('Could not find any templates for key:', key);
    return null;
  }
  if (options.textTemplates[key].constructor.name !== 'Array') {
    console.error(`Templates for key '${key}' are not of type Array!`);
    return null;
  }
  if (options.textTemplates[key].length < 1) {
    console.warn(`Templates for key '${key}' are empty!`);
    return null;
  }
  return options.textTemplates[key];
}