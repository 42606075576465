import axios from 'axios' // Vendor Module
import ApiErrorService from './ApiErrorService'



/**
 * Singleton storage.
 */
let instance = null


/**
 * Singleton export.
 */
export default (userOptions) => {
  if (instance !== null) {
    return instance
  }
  return instance = new ApiService (userOptions)
}


/**
 * Private stored defaultOptions for the module.
 */
let options = {
  instances: [
    {
      name: 'default',
      postContentType: null,
      config: {
        baseUrl: '/'
      }, // axios config
    }
  ]
}


class ApiService {
  /**
   * The ApiService wraps and offers axios instances for usage as endpoint I/O.
   * @param {object} userOptions - The Configuration for this service
   * @return {ApiService}
   */
  constructor (userOptions) {
    options = {...options, ...userOptions}
    this.instances = {}
    return this
  }


  /**
   * Initializes the Module.
   * @return {ApiService}
   */
  init () {
    options.instances.forEach((instanceOptions) => {
      this.instances[instanceOptions.name] = axios.create(instanceOptions.config)
    });
    ApiErrorService().init()
    console.log('INIT:', this)
    return this
  }


  /**
   * Sends a GET request to an endpoint.
   * @param {String} endpointUri - The uri the request will be send to
   * @param {Object} params - The get params as an object
   */
  get (endpointUri, params, instance) {
    instance = instance || 'default'
    return this.instances[instance]
      .get(endpointUri, {params: params})
  }


  /**
   * Sends a POST request to an endpoint.
   * It automatically converts the payload to 'x-www-form-urlencoded' if configured for the instance.
   * @param {String} endpointUri - The uri the request will be send to
   * @param {Object} payload - The payload as object
   * @param {String} instanceName - Name of the used instance
   * @return {Promise}
   */
  post (endpointUri, payload, instanceName) {
    instanceName = instanceName || 'default'
    const contentType = this.getContentType(instanceName)
    return this.instances[instanceName]
      .post(endpointUri, this.preparePayload(payload, contentType))
      .catch((error) => {
        ApiErrorService().handleError(error)
        return Promise.reject(error)
      })
  }


  /**
   * Sends a DELETE request to an endpoint.
   * @param {String} endpointUri - The uri the request will be send to
   * @param {String} instanceName - Name of the used instance
   * @return {Promise}
   */
  delete (endpointUri, instanceName) {
    instanceName = instanceName || 'default'
    return this.instances[instanceName]
      .delete(endpointUri)
  }


  /**
   * Sends a PUT request to an endpoint.
   * @param {String} endpointUri - The uri the request will be send to
   * @param {String} instanceName - Name of the used instance
   * @return {Promise}
   */
  put (endpointUri, payload, instanceName) {
    instanceName = instanceName || 'default'
    const contentType = this.getContentType(instanceName)
    return this.instances[instanceName]
      .put(endpointUri, this.preparePayload(payload, contentType))
  }


  /**
   * Sends a PATCH request to an endpoint.
   * @param {String} endpointUri - The uri the request will be send to
   * @param {String} instanceName - Name of the used instance
   * @return {Promise}
   */
  patch (endpointUri, payload, instanceName) {
    instanceName = instanceName || 'default'
    const contentType = this.getContentType(instanceName)
    return this.instances[instanceName]
      .patch(endpointUri, this.preparePayload(payload, contentType))
  }


  /**
   * Returns the contentType for a specific instance.
   * @param {String} instanceName - Name of the used instance
   * @return {String} The contentType of the instance
   */
  getContentType (instanceName) {
    instanceName = instanceName || 'default'
    const instance = this.instances[instanceName]
    let contentType = null
    if (instance.defaults.hasOwnProperty('headers')) {
      if (instance.defaults.headers.hasOwnProperty('Content-Type')) {
        contentType = instance.defaults.headers['Content-Type']
      }
    }
    return contentType
  }


  /**
   * Converts an object into set contentType.
   * For now it supports only 'x-www-form-urlencoded' contentType.
   * @param {Object} payload - The payload that requires conversion
   * @param {String} contentType - The contentType that should be converted to
   */
  preparePayload (payload, contentType) {
    // console.log('preparing payload', payload, 'to match', contentType)
    if (contentType === 'application/x-www-form-urlencoded') {
      const params = new URLSearchParams()
      if (typeof payload === 'object') {
        for (let prop in payload) {
          if (payload.hasOwnProperty(prop)) {
            params.append(prop, payload[prop])
          }
        }
      }
      // console.log('returning payload', params)
      return params
    } else {
      return payload
    }
  }
}
