/**
 * Meta-Tags with name specified here will be excluded from output.
 */
const excludeNames = [
  'viewport'
];


/**
 * Collects all meta tags and reduces them into an object of {meta[name]: meta[content]}.
 * @param {Array} userExcludes - An Array of Strings that will be excluded if matches meta[name]
 */
export const getPageMeta = (userExcludes) => {
  userExcludes = userExcludes || [];
  return [...document.querySelectorAll(`meta[name]`)]
      .filter((item) => ![...excludeNames,...userExcludes].includes(item.name))
      .reduce((obj, item) => Object.assign(obj, {[item.name]: item.content}), {});
};