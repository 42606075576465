/**
 * Singleton storage.
 */
let instance = null


/**
 * Singleton export.
 */
export default (userOptions) => {
  if (instance !== null) {
    return instance
  }
  return instance = new StatusService (userOptions)
}


/**
 * Private stored defaultOptions for the module.
 */
let options = {
  selector: 'data-joboffer-state',
  replaceTarget: 'data-joboffer-header',
  refreshTime: 1500,
  url: `https://anzeigenschaltung.de/job_offers/[jobUri]`, // Endpoint where the current status can be parsed from
  checkStatusOn: ['draft', 'pending', 'offline'],
  forceCheckStatusOn: ['pending'], // Forces status watching on init
}


class StatusService {
  /**
   * The status service checks the status of an JobOffer.
   * If the service is 'pending' it checks for a new status until it changes to 'online'.
   * @param {Object} userOptions - The configuration for this service
   * @return {StatusService}
   */
  constructor (userOptions) {
    options = {...options, ...userOptions}
    return this
  }


  /**
   * Initializes the Module.
   * @return {StatusService}
   */
  init () {
    this.element = document.querySelector(`[${options.selector}]`)
    if (this.element === null) {
      console.warn('Could not find the status within the DOM with configured selector. Skipping...')
      return null;
    }
    this.status = this.element.getAttribute(options.selector)
    if (this.element === null) {
      console.warn('Could not find the status within selected status element. Skipping...')
    }
    if (options.forceCheckStatusOn.includes(this.status)) {
      this.watchStatus();
    }
    console.log('INIT:', this)
    return this
  }

  
  /**
   * Watches status until it changes to an allowed configured value.
   * @params {string|boolean} untilStatusChange - Ignores allowed list and watches until status changes
   * @return {Promise} - The resolved promise will be a boolean value
   */
  watchStatus (untilStatusChange = false) {
    return new Promise((resolve, reject) => {
      // CASE 1: Only check if current status matches "checkStatusOn"
      if (options.checkStatusOn.includes(this.status)) {
        console.log('Watching status changes... (case 1)')
          setTimeout(() => {
            this.updateStatus()
              .then((hasChanged) => this.watchStatus())
              .then(() => resolve(true))
          }, options.refreshTime)
      } 
      // CASE 2: Force check until status changes
      else if (untilStatusChange) {
        console.log('Watching status changes... (case 2)')
          setTimeout(() => {
            this.updateStatus()
              .then((hasChanged) => {
                if (!hasChanged) {
                  this.watchStatus(true).then(() => {
                    console.log('Stopped watching status changes. (case 2)')
                    resolve(true)
                  })
                } else {
                  console.log('Stopped watching status changes. (case 2)')
                  resolve(true)
                }
              })
          }, options.refreshTime)
      // CASE 3: Not forced and not matching "checkStatusOn"
      } else {
        console.log('Not watching for status changes. (case 3)')
        resolve(true)
      }
    })
  }


  /**
   * Async function that fetches the page html configured by url.
   * @return {boolean} - status has changed
   */
  updateStatus () {
    return fetch(options.url)
      .then((response) => getTargetFromResponse(response))
      .then((newTargetElement) => {
        if (newTargetElement.querySelector(`[${options.selector}]`).getAttribute(options.selector) !== this.status) {
          // Status has changed
          this.setStatus(newTargetElement)
          return true
        } else {
          // Status has not changed
          return false
        }
      })
  }


  /**
   * Sets a new status by replacing the target element from the XHR response.
   * @param {HTMLElement} - The target element that will be added to DOM
   */
  setStatus (newTargetElement) {
    const oldTargetElement = document.querySelector(`[${options.replaceTarget}]`)
    const parentElement = oldTargetElement.parentNode
    parentElement.removeChild(oldTargetElement)
    parentElement.insertBefore(newTargetElement, parentElement.firstChild)
    this.element = newTargetElement.querySelector(`[${options.selector}]`)
    this.status = this.element.getAttribute(options.selector)
  }
}


/**
 * Parses the target element from a XHR response.
 * @param {object} response - A XHR response object
 * @return {HTMLElement} - The target element
 */
function getTargetFromResponse (response) {
  return response
    .text()
    .then((html) => {
      const parser = new DOMParser(),
        doc = parser.parseFromString(html, 'text/html'),
        newStatus = doc.querySelector(`[${options.replaceTarget}]`)
      return newStatus
    })
}