import EditListItem from './editList-item'
import TextareaAutosize from '../textareaAutosize'


/**
 * The Components default options.
 */
const defaultOptions = {
  attrName: 'name',
  template: `
    <div class="editList-item" data-edit-list-item data-edit-list-item-type="text">
      <textarea class="form-control" rows="1"></textarea>
      <div class="editList-item-panel">
        <svg data-edit-list-btn="remove">
          <use xlink:href="#icon-close" />
        </svg>
      </div>
    </div>
  `
}


export default class EditListTextItem extends EditListItem {
  constructor (parent, userOptions) {
    super(parent, {...defaultOptions, ...userOptions})
    this.type = 'text'
    return this
  }

  
  /**
   * Initialize the Module. 
   */
  init () {
    this.input = this.element.querySelector('textarea')
    this.input.setAttribute('name', `${this.options.attrName}[]`)
    this.initInput()
    this.initBtnRemove()
    return this
  }


  /**
   * Initializes the input field.
   */
  initInput () {
    this.input.addEventListener('keydown', (event) => {
      // ESC: Blur the field
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.input.blur()
      }
      // BACKSPACE: Empty ? this.destroy() + focus previous
      if (event.key === 'Backspace' && this.input.value.length < 1) {
        this.parent.focusItem(this.index - 1)
        this.destroy()
      }
      // DELETE: Empty ? this.destroy() + focus next
      if (event.key === 'Delete' && this.input.value.length < 1) {
        this.parent.focusItem(this.index + 1)
        this.destroy()
      }
      // ENTER: Create a new field below this one
      if (event.key === 'Enter') {
        event.preventDefault()
        const newItem = this.parent.addItem(this.index + 1)
        this.parent.focusItem(newItem.index)
      }
      this.parent.element.dispatchEvent(new CustomEvent('item:change'))
    })
    // Listen to focus/blur
    this.input.addEventListener('focus', event => this.element.classList.add('is-focused'))
    this.input.addEventListener('blur', event => this.element.classList.remove('is-focused'))
    // Attach autosizer
    setTimeout(() => new TextareaAutosize().init(this.input), 5)
  }


  /**
   * Initialize the remove button.
   */
  initBtnRemove () {
    this.element
      .querySelector('[data-edit-list-btn="remove"]')
      .addEventListener('click', event => this.destroy())
  }


  /**
   * Sets the value of this item.
   * @param {string} itemValue - The new value of this item
   * @return {boolean}
   */
  setValue (itemValue) {
    if (typeof itemValue !== 'string') {
      console.error('Cannot set value of EditListTextItem! It requires a string passed!')
      return false
    }
    this.input.value = itemValue
    return true
  }
}