// Helper
import { getPageMeta } from './helper/getMeta';

// Services
import NotifierService from './services/Notifier/NotifierService';
import ModalService from './services/Modal/ModalService';
import ApiService from './services/Api/ApiService';
import PublisherService from './services/Publisher/PublisherService';
import StatusService from './services/Status/StatusService';


// Components
import animateColumn from './animateCol'; // TODO: Optimize this component
import { loadTextareaAutosize } from './components/textareaAutosize';
import { loadEditLists } from './components/editList/editList';
import { loadTextTemplates } from "./components/textTemplate/TextTemplate";
import { loadFormValidators } from './components/FormValidator';
// import { loadFormValidators } from './components/formValidator/FormValidator';


window.addEventListener('DOMContentLoaded', (event) => {

  // Stores pageMeta data
  const pageMeta = window.pageMeta = getPageMeta();
  let jobofferUriElement = document.querySelector('[data-joboffer-uri]'),
  jobofferUri = null
  if (jobofferUriElement) {
    jobofferUri = jobofferUriElement.getAttribute('data-joboffer-uri');
  }
  

  // 1: Init Services
  window.Notifier = NotifierService().init();
  ModalService().init();
  ApiService({
    instances: [
      {
        name: 'default', 
        config: { // axios config
          baseURL: pageMeta.appUrl,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CSRF-TOKEN': pageMeta['csrf-token'],
          },
        }
      },
    ]
  }).init();

  // 2: Load Service for specific views
  if (pageMeta.viewMode === 'joboffers-show') {
    
    window.PublisherService =  PublisherService({
      uri: {
        publish: `job_offers/${jobofferUri}/placement`, // Endpoint that the PublisherService publishes at
        unpublish: `job_offers/${jobofferUri}/placement/delete`, // Endpoint that the PublisherService unpublishes at
      },
      payload: { [pageMeta['csrf-param']]: pageMeta['csrf-token'] }
    }).init();

    window.StatusService = StatusService({
      url: `${pageMeta.appUrl}/job_offers/${jobofferUri}` // Endpoint where the current status can be parsed from
    }).init();
  }

  
  // Load Components
  window.resizer = new animateColumn().init(); // TODO: Optimize!
  loadTextareaAutosize();
  window.editLists = loadEditLists(); // TODO: Integrate Storage system for this
  loadTextTemplates();


  // Load Validation
  // Set validationMode to "default" if joboffer is currently online
  const validationMode = (document.querySelector(`[data-joboffer-state="online"]`)) ? 'default' : 'soft'
  loadFormValidators({
    mode: validationMode,  // 'default' = validates after submit click | 'soft' = validates always but allows submit
    validate: [
      {
        attr: 'data-edit-list',
        value: 'working_times',
        rule: ['requiredDay', 'correctTimeDuration'],
        type: 'EditList-datetime',
      },
      {
        attr: 'name',
        value: 'job_offer[title]',
        rule: ['required', 'annotation'],
        type: 'input'
      },
      {
        attr: 'name',
        value: 'job_offer[responsible_tcsm_slack_email]',
        rule: ['required', 'annotation'],
        type: 'input'
      }, {
        attr: 'name',
        value: 'job_offer[intro_phrase_1]',
        rule: ['required', 'annotation'],
        type: 'input'
      }, {
        attr: 'name',
        value: 'job_offer[intro_phrase_2]',
        rule: ['required', 'annotation'],
        type: 'input'
      }, {
        attr: 'data-edit-list',
        value: 'profile',
        rule: ['requiredItem', 'annotation'],
        type: 'EditList'
      }, {
        attr: 'data-edit-list',
        value: 'responsibilities',
        rule: ['requiredItem', 'annotation'],
        type: 'EditList'
      }, {
        attr: 'data-edit-list',
        value: 'keydata',
        rule: ['requiredItem', 'annotation'],
        type: 'EditList'
      }, {
        attr: 'name',
        value: 'job_offer[outro]',
        rule: ['required', 'annotation'],
        type: 'input'
      },
    ]
  });

  document.querySelectorAll('.disable-on-click').forEach(function(element) {
    element.addEventListener('click', function() {
      if(this.form) {
        this.form.submit();
      }

      this.disabled = true;
    });
  });
});
