// TODO: Replace this Validator with the new formValidator component
let options = {
  selector: '[data-validate-form]',
  mode: 'default', // 'default' = validates after submit click | 'soft' = validates always but allows submit
  // Classes
  validClass: 'is-valid',
  invalidClass: 'is-invalid',
  // Validation Field Config
  validate: [ // Example configuration
    {
      attr: 'id',
      value: 'my-input-field',
      rule: ['required', 'annotation'],
      type: 'input',
    }
  ],
  // Feedback messages the appears on validation error
  errorMessages: {
    'required': 'Dieses Feld darf nicht leer sein!',
    'annotation': 'Alle [Platzhalter] müssen ersetzt werden!',
    'requiredItem': 'Es muss wenigstens ein Listen-Punkt eingetragen sein!',
    'requiredDay': 'Es muss wenigstens ein Tag ausgewählt sein!',
    'correctTimeDuration': 'Der Zeitraum muss korrekt eingetragen oder leer sein!'
  },
}

export const loadFormValidators = (userOptions) => {
  const collection = []
  options = {...options, ...userOptions}
  const elements = document.querySelectorAll(options.selector)
  if (elements !== null) {
    elements.forEach((element) => {
      collection.push(new FormValidator(element, options).init())
    })
  }
}

export default class FormValidator {
  constructor (element, userOptions) {
    options = {...options, ...userOptions}
    this.element = element
    this.fields = []
    this.validate = true
    this.lockFormSubmit = false // if true the form cannot be submitted
    return this
  }

  // Initialize Module
  init () {
    // Check Setting
    this.validate = (this.element.getAttribute('data-validate-form') === 'true')
    if(!this.validate) {
      return this
    }
    // Mode: 'default'
    // => Default validation is activated when user clicks on submit. Submit is blocked until form is valid
    if (options.mode === 'default') {
      this.checkFields()
      this.element.addEventListener('submit', (event) => {
        this.checkFields()
        const isValid = this.isValid()
        if (isValid === false) {
          event.preventDefault()
        }
      })
    }
    // Mode: 'soft'
    // => Soft validation is always activated and allows submit of form even if the form is invalid
    if (options.mode === 'soft') {
      this.checkFields()
    }
    console.log('INIT', this)
    return this
  }


  /**
   * Check all fields validation state to get form validation state.
   * @return {boolean} - The validation state of this form
   */
  isValid () {
    let isValid = true
    this.fields.forEach((fieldState) => {
      fieldState === false ? isValid = false : ''
    })
    return isValid
  }


  /**
   * Checks all configured fields within the FormValidator Element.
   */
  checkFields () {
    options.validate.forEach((validationConfig, index) => {
      const field = this.element.querySelector(`[${validationConfig.attr}="${validationConfig.value}"]`)
      if (field === null) {
        console.warn('Could not find field for validation, for config:', validationConfig)
        return
      }
      switch (validationConfig.type) {
        case 'input':
          this.validateInput(field, validationConfig.rule, index)
          field.addEventListener('keyup', (event) => {
            this.validateInput(field, validationConfig.rule, index)
          })
          field.addEventListener('change', (event) => {
            this.validateInput(field, validationConfig.rule, index)
          })
          break
        case 'EditList':
          this.validateEditList(field, validationConfig.rule, index, true)
          field.addEventListener('item:change', (event) => {
            this.validateEditList(field, validationConfig.rule, index, false)
          })
          field.addEventListener('item:remove', (event) => {
            this.validateEditList(field, validationConfig.rule, index, false)
          })
          field.addEventListener('item:add', (event) => {
            const ItemInput = event.detail.Item.input
            ItemInput.addEventListener('keyup', (event) => {
              this.validateEditList(field, validationConfig.rule, index, false)
            })
            ItemInput.addEventListener('change', (event) => {
              this.validateEditList(field, validationConfig.rule, index, false)
            })
          })
          break
        case 'EditList-datetime':
          field.addEventListener('item:change', event => {
            this.validateEditListDatetime(field, validationConfig.rule, index)
          })
          field.addEventListener('item:remove', event => {
            this.validateEditListDatetime(field, validationConfig.rule, index)
          })
          field.addEventListener('item:add', event => {
            this.validateEditListDatetime(field, validationConfig.rule, index)
          })
          break
      }
    })
  }


  /**
   * Validates an input field using a rule for validation and an index to map it to FormValidator.
   * @param {object} field - The HTMLElment of the field that should be validated
   * @param {string} rule - The validation rule e.g. "required"
   * @param {number} index - Any number as index to store the validation state to controller
   */
  validateInput (field, rule, index) {
    let isValid = true,
      invalidRule = '',
      fieldValue = field.value.trim()
    if (rule.includes('required') && !(fieldValue.length > 0)) {
      isValid = false
      invalidRule = 'required'
    }
    if (rule.includes('annotation') && !isAnnotationValid(field.value)) {
      isValid = false
      invalidRule = 'annotation'
    }

    // Reset validation classes
    // field.classList.remove(options.validClass)
    field.classList.remove(options.invalidClass)
    const oldFeedback = field.parentNode.querySelector('.invalid-feedback')
    if (oldFeedback) {
      field.parentNode.removeChild(oldFeedback)
    }
    // Set validation classes
    if (isValid) {
      // field.classList.add(options.validClass)
    } else {
      field.parentNode.appendChild(getFeedbackElement(invalidRule))
      field.classList.add(options.invalidClass)
    }
    this.fields[index] = isValid
    this.isValid()
    return isValid
  }


  /**
   * Validates an EditList using a rule for validation and an index to map it to FormValidator.
   * @param {object} editListElement - The HTMLElment of the field that should be validated
   * @param {string} rule - The validation rule e.g. "required"
   * @param {number} index - Any number as index to store the validation state to controller
   */
  validateEditList (editListElement, rule, index, removeEmptyItems = false) {
    // removeEmptyItems = removeEmptyItems ? removeEmptyItems : false
    let isValid = true,
      invalidRule = ''
    // Access list controller and removeEmptyItems()
    const identifier = editListElement.getAttribute('data-module-id')
    const EditList = window.editLists.filter(EditListController => EditListController.identifier === identifier)[0]
    if (removeEmptyItems) {
      EditList.removeEmptyItems()
    }
    // Checkfor requiredItem
    if (rule.includes('requiredItem')) {
      if (EditList.items.length === 0) {
        isValid = false
        invalidRule = 'requiredItem'
      } else {
        EditList.items.forEach((Item) => {
          if (Item.input.value.length === 0) {
            isValid = false
            invalidRule = 'requiredItem'
          }
        })
      }
    }
    // Check for annotation
    if (rule.includes('annotation')) {
      EditList.items.forEach((Item) => {
        if (!isAnnotationValid(Item.input.value)) {
          isValid = false
          invalidRule = 'annotation'
        }
      })
    }
    // Reset validation classes
    // EditList.element.classList.remove(options.validClass)
    EditList.element.classList.remove(options.invalidClass)
    const oldFeedback = EditList.element.querySelector('.invalid-feedback')
    if (oldFeedback) {
      EditList.element.removeChild(oldFeedback)
    }
    // Set state
    if (isValid) {
      // EditList.element.classList.add(options.validClass)
    } else {
      EditList.element.appendChild(getFeedbackElement(invalidRule))
      EditList.element.classList.add(options.invalidClass)
    }
    this.fields[index] = isValid
    this.isValid()
    return isValid
  }

  
  /**
   * Validates an EditList-dateTime using a rule for validation and an index to map it to FormValidator.
   * @param {object} editListElement - The HTMLElment of the field that should be validated
   * @param {string} rule - The validation rule e.g. "required"
   * @param {number} index - Any number as index to store the validation state to controller
   */
  validateEditListDatetime (editListElement, rule, index) {
    let isValid = true, invalidRule = ''
    // Access list controller
    const identifier = editListElement.getAttribute('data-module-id')
    const EditList = window.editLists.filter(EditListController => EditListController.identifier === identifier)[0]
    // Check for requiredDay
    if (rule.includes('requiredDay')) {
      // Check if all entries have a day set
      EditList.items.forEach(Item => {
        if (Item.selection.length === 0) {
          isValid = false
          invalidRule = 'requiredDay'
        }
      })
    }
    // Check for correctTimeDuration
    if (rule.includes('correctTimeDuration')) {
      // Check if all time entries are correct
      EditList.items.forEach(Item => {
        let hasValue = 0,
          timeInputs = [
            Item.input.start.input.hour,
            Item.input.start.input.minute,
            Item.input.stop.input.hour,
            Item.input.stop.input.minute
          ]
        
        timeInputs.forEach(timeInput => {
          if (timeInput.value.length > 0) {
            hasValue++
          }
        })
        if (hasValue > 0 && hasValue < 4) {
          isValid = false
          invalidRule = 'correctTimeDuration'
        }
      })
    }
    // Reset validation classess
    EditList.element.classList.remove(options.invalidClass)
    const oldFeedback = EditList.element.querySelector('.invalid-feedback')
    if (oldFeedback) {
      EditList.element.removeChild(oldFeedback)
    }
    // Set state
    if (isValid) {
      // EditList.element.classList.add(options.validClass)
    } else {
      EditList.element.appendChild(getFeedbackElement(invalidRule))
      EditList.element.classList.add(options.invalidClass)
    }
    this.fields[index] = isValid
    this.isValid()
    // Lock form submit if it is not valid
    this.setLockFormSubmit(!isValid)
    return isValid
  }

  
  /**
   * Un/locks the form submit .
   * @param {boolean} setTo - Set to true to prevent default submit behavior
   */
  setLockFormSubmit (setTo) {
    if (setTo === true) {
      this.lockFormSubmit = true
      this.element.addEventListener('submit', lockSubmit)
      this.element
        .querySelectorAll('[type="submit"]')
        .forEach(btnElement => btnElement.setAttribute('disabled', true))
    } else {
      this.lockFormSubmit = false
      this.element.removeEventListener('submit', lockSubmit)
      this.element
        .querySelectorAll('[type="submit"]')
        .forEach(btnElement => btnElement.removeAttribute('disabled'))
    }
  }
}


/**
 * Checks a string if it contains square brackets.
 * @param {String} content - Any string that should be checked
 */
function isAnnotationValid (content) {
  const matchRule = /\[.*\]/g
  const matchResult = content.match(matchRule) || []
  return !(matchResult.length > 0)
}


/**
 * Constructs a feedback message element for the passed rule.
 * @param {String} rule - A validation rule
 * @return {HTMLElement} - The feedback message element
 */
function getFeedbackElement (rule) {
  const feedbackElement = document.createElement('div')
  feedbackElement.classList.add('invalid-feedback')
  feedbackElement.innerHTML = options.errorMessages[rule]
  return feedbackElement
}


/**
 * Locks submit of form.
 */
function lockSubmit () {
  event.preventDefault()
}
