import { parseHtml } from '../../helper/html';

/**
 * Private stored defaultOptions for the module.
 */
let options = {
  injectTo: `main`,
  templates: {
    modal: `
      <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-body"></div>
          </div>
        </div>
      </div>
    `,
    header: `
      <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    `,
    footer: `
      <div class="modal-footer"></div>
    `,
  }
};

/**
 * Singleton storage.
 */
let instance = null;

/**
 * Singleton export.
 */
export default (userOptions) => {
  if (instance !== null) {
    return instance;
  }
  return instance = new ModalService (userOptions);
};

class ModalService {
  /**
   * The ModalService offers to fill the modal with content and to display or hide it.
   * @param {object} userOptions - The Configuration for this service
   * @return {ModalService}
   */
  constructor (userOptions) {
    options = {...options, ...userOptions};
    this.element = parseHtml(options.templates.modal)[0];
    this.isVisible = false;
    console.log('INIT', this, options);
    return this;
  }


  /**
   * Initializes the Module.
   */
  init () {
    function closeHandler (Modal) {
      Modal.hide();
    }
    const hide = (event) => closeHandler(this);
    // (Re)initializes close clickhandlers
    this.element
      .querySelectorAll('[data-dismiss="modal"]')
      .forEach((dismissElement) => {
        dismissElement.removeEventListener('click', hide);
        dismissElement.addEventListener('click', hide);
      });
    document.querySelector(options.injectTo).appendChild(this.element);
    return this;
  }


  /**
   * Displays the header and adds a title to it.
   * @param {String} title - The title as string
   * @return {Modal}
   */
  setTitle (title) {
    if (typeof title !== 'string') {
      console.warn('The param passed to Modal.setTitle is not of type string!', title);
      return this;
    }
    // Only inject header once
    if (!this.element.querySelector('.modal-title')) {
      const headerElement = parseHtml(options.templates.header)[0];
      const modalBody = this.element.querySelector('.modal-body');
      modalBody.parentNode.insertBefore(headerElement, modalBody);
    }
    this.element.querySelector('.modal-title').textContent = title;
    return this;
  }


  /**
   * Adds content to the modal body.
   * @param {String} content - Content as a string that contains a HTML structure
   * @return {Modal}
   */
  setBody (content) {
    let contentElements = content;
    const modalBody = this.element.querySelector('.modal-body');
    modalBody.innerHTML = '';
    if (typeof content === 'string') {
      contentElements = parseHtml(content);
      Array.from(contentElements).forEach((contentElement) => {
        modalBody.appendChild(contentElement);
      });
    } else {
      if (contentElements.constructor.name === 'NodeList') {
        Array.from(contentElements).forEach((contentElement) => {
          modalBody.appendChild(contentElement);
        });
      } else {
        modalBody.appendChild(contentElements);
      }
    }
    return this;
  }


  /**
   * Displays the footer and adds content to it.
   * @param {String} content - Content as a string that contains a HTML structure
   * @return {Modal}
   */
  setFooter (content) {
    let footerElement = this.element.querySelector('.modal-footer');
    if (footerElement) {
      footerElement.innerHTML = '';
    } else {
      footerElement = parseHtml(options.templates.footer)[0];
      this.element
        .querySelector('.modal-body').parentNode
        .appendChild(footerElement);
    }
    const contentElements = parseHtml(content);
    Array.from(contentElements).forEach((contentElement) => {
      footerElement.appendChild(contentElement);
    });
    return this;
  }


  /**
   * Resets modal to the construction state.
   * @return {Modal}
   */
  reset () {
    this.hide();
    this.element.parentNode.removeChild(this.element);
    this.element = parseHtml(options.templates.modal)[0];
    this.init();
    return this;
  }


  /**
   * Shows the modal.
   */
  show () {
    if (this.isVisible) {
      return false;
    }
    this.element.style.display = 'block';
    setTimeout(() => {
      const backdropElement = parseHtml('<div class="modal-backdrop fade show"></div>')[0];
      document.body.append(backdropElement);
      document.body.classList.add('modal-open');
      this.element.classList.add('show');
      this.element.removeAttribute('aria-hidden');
      this.element.setAttribute('aria-modal', 'true');
      this.isVisible = true;
    }, 0);
  }


  /**
   * Hides the modal.
   */
  hide () {
    if (!this.isVisible) {
      return false;
    }
    const backdropElement = document.querySelector('.modal-backdrop');
    backdropElement.classList.remove('show');
    document.body.classList.remove('modal-open');
    this.element.classList.remove('show');
    this.element.setAttribute('aria-hidden', 'true');
    this.element.removeAttribute('aria-modal');
    setTimeout(() => {
      this.element.style.display = 'none';
      document.body.removeChild(document.querySelector('.modal-backdrop'));
      this.isVisible = false;
    }, 300);
  }
}