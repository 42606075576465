let options = {
  initState: 'opened', // 'opened' | 'closed'
  btnHideText: 'verstecken',
  btnShowText: 'anzeigen',
};

export default class animateColumn {
  /**
   * 
   * @param {object} userOptions - options that override the defaults
   */
  constructor (userOptions) {
    options = userOptions ? { ...options, ...userOptions } : options;
    this.elements = {};
    this.state = null;
    return this;
  }


  /**
   * Initializes the Module.
   */
  init () {
    this.elements.reduce = document.querySelector('[data-sizing-col="reduce"]');
    this.elements.extend = document.querySelector('[data-sizing-col="extend"]');
    this.elements.static = document.querySelector('[data-sizing-col="static"]');
    this.elements.toggle = document.querySelector('[data-sizing-btn="toggle"]');
    if (!this.elements.reduce || !this.elements.extend || !this.elements.static) {
      return null;
    }

    // Configure initial state
    if (options.initState === 'opened') {
      this.state = 'opened';
    } else {
      this.close();
    }
    // Override initial state by data-attr
    const initDataState = this.elements.toggle.getAttribute('data-sizing-init');
    if (initDataState !== null) {
      if (initDataState === 'opened') {
        this.state = 'opened';
      }
      if (initDataState === 'closed') {
        this.close();
      }
    }
    // Add Listener to button
    this.elements.toggle.addEventListener('click', (event) => {
      this.toggle();
    });
    return this;
  }


  /**
   * Toggles the Column.
   */
  toggle () {
    if (this.state === 'opened') {
      this.close();
    } else {
      this.open();
    }
  }


  /**
   * Closes the column.
   */
  close () {
    // Get Static: <currentWidth> (for each animation process)
    const currentWidth = this.elements.static.offsetWidth;
    // For Static: width: <currentWidth>
    this.elements.static.style.width = currentWidth + 'px';
    // For Reduce: flex-basis: 0%, max-width: 0
    this.elements.reduce.style['flex-basis'] = 0;
    this.elements.reduce.style['max-width'] = 0;
    // For Extend: flex-basis: 100%, max-width: 100%
    this.elements.extend.style['flex-basis'] = '100%';
    this.elements.extend.style['max-width'] = '100%';
    // Change states and texts
    this.state = 'closed';
    this.elements.toggle.innerText = options.btnShowText;
    return this;
  }


  /**
   * Opens the column.
   */
  open () {
    // For Static: width: suto
    this.elements.static.style.width = 'auto';
    // For Reduce: flex-basis: 50%, max-width: 50%
    this.elements.reduce.style['flex-basis'] = '50%';
    this.elements.reduce.style['max-width'] = '50%';
    // For Extend: flex-basis: 50%, max-width: 50%
    this.elements.extend.style['flex-basis'] = '50%';
    this.elements.extend.style['max-width'] = '50%';
    // Change states and texts
    this.state = 'opened';
    this.elements.toggle.innerText = options.btnHideText;
    return this;
  }
}