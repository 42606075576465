import Notification from './Notification';


/**
 * Default options for the module. Passed userOptions will override them.
 */
let options = {
  rootElement: 'main',
  displayTime: 4000,
  notificationOptions: {},
};


/**
 * Singleton storage.
 */
let instance = null;


/**
 * Singleton export.
 */
export default (userOptions) => {
  if (instance !== null) {
    return instance;
  }
  return instance = new NotifierService (userOptions);
};


class NotifierService {
  /**
   * The NotifierService offers the creation and display of several kind of notifications.
   * @param {object} userOptions - The Configuration for this service
   * @return {NotifierService}
   */
  constructor (userOptions) {
    options = {...options, ...userOptions};
    return this;
  }
  

  /**
   * Initializes the Module.
   * @return {NotifierService}
   */
  init () {
    this.stack = []; // TODO: Add a stack and identifier to each notification object.
    console.log('INIT:', this);
    return this;
  }


  /**
   * Creates and displays a notification.
   * @param {String} content - A String with either Text or HTML that will be injected into the notification
   * @param {String} type - The type is either: 'success', 'warning', 'danger', 'info'
   */
  createNotification (content, type) {
    const notification = new Notification(options.notificationOptions)
      .init()
      .setType(type)
      .setContent(content);
    this.showNotification(notification, options.displayTime);
    return notification;
  }


  /**
   * Displays a notification to the rootElement.
   * @param {object} notification - A constructed notification object
   * @param {number} timer - The time in ms the notification will be displayed for
   * @return {NotifierService}
   */
  showNotification (notification, timer) {
    timer = typeof timer !== 'undefined' ? timer : null;
    const rootElement = document.querySelector(options.rootElement);
    notification.show();
    if (rootElement === null) {
      console.error('Could not find rootElement. Please check your configuration!');
      return false;
    }
    rootElement.append(notification.element);
    if (timer !== null) {
      setTimeout(() => {
        notification.hide();
      }, timer);
    }
    return this;
  }
}