import { parseHtml } from '../../helper/html'
// TODO:
// * When user presses DEL while on last digit of 1st input: delete 2nd input 1st digit


/**
 * Private storage for all occurences of this component.
 */
let ComponentStorage = []


/**
 * The Components default options.
 */
const defaultOptions = {
  maxHourValue: 23,
  minHourValue: 0,
  maxMinuteValue: 59,
  minMinuteValue: 0,
  template: `
    <div class="input-time" data-input-time>
      <input type="text" />
      <div class="seperator">:</div>
      <input type="text" />
    </div>
  `,
}


/**
 * Traverses the DOM and maps all InputTimes to each found element.
 * @param {object} userOptions - (optional) The user configuration object for each EditList (default: {})
 * @param {HTMLElement} context - (optional) The node on wich the dom traversion should be started (default: document)
 * @param {boolean} autoInit - (optional) Automatically runs init of each found component (default: true)
 * @return {array} - The component's complete unscoped collection
 */
export const loadInputTimes = (userOptions = {}, context = document, autoInit = true) => {
  context.querySelectorAll('[data-input-time]')
    .forEach((inputTimeElement) => {
      const Component = new InputTime(inputTimeElement, userOptions)
      if (autoInit) {
        Component.init()
      }
    })
  return ComponentStorage
}


export default class InputTime {
  constructor (element, userOptions) {
    this.element = element
    this.options = {...defaultOptions, ...userOptions}
    this.index = ComponentStorage.push(this) - 1
    this.identifier = `InputTime_${this.index}`
    this.value = null
    this.input = { hour: null, minute: null }
    return this
  }


  /**
   * Initializes the module.
   */
  init () {
    this.initTemplate()
    this.initInputs()
    return this
  }
  
  
  /**
   * Initializes the template
   */
  initTemplate () {
    const parentElement = this.element.parentNode
    const newElement = parseHtml(this.options.template)[0]
    parentElement.replaceChild(newElement, this.element)
    this.element = newElement
    this.element.setAttribute('data-module-id', this.identifier)
  }


  /**
   * Initializes the input fields.
   */
  initInputs () {
    const inputFields = this.element.querySelectorAll('input')
    this.input.hour = inputFields[0]
    this.input.minute = inputFields[1]
    // Allowed Keycodes:
    // backspace: 8, delete: 46, enter: 13, tab: 9, shift: 16, ctrl: 17, left/right/up/down: 37/39/38/40, home: 36, end: 35
    // alt: 18, window/command: 91, ESC: 27
    this.editKeys = [8, 9, 13, 16, 17, 18, 27, 35, 36, 37, 38, 39, 40, 46, 91]
    this.numberKeys = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57] // numbers 1-9: 48-57
    // Add Listeners for hour input
    this.input.hour.addEventListener('keydown', event => this.keydownHandler(event, 'hour'))
    this.input.hour.addEventListener('keyup', event => this.keyupHandler(event, 'hour'))
    this.input.hour.addEventListener('change', event => this.changeHandler(event, 'hour'))
    this.input.hour.addEventListener('focus', event => this.focusHandler(event, 'hour'))
    // Add Listeners for minute input
    this.input.minute.addEventListener('keydown', event => this.keydownHandler(event, 'minute'))
    this.input.minute.addEventListener('keyup', event => this.keyupHandler(event, 'minute'))
    this.input.minute.addEventListener('change', event => this.changeHandler(event, 'minute'))
    this.input.minute.addEventListener('focus', event => this.focusHandler(event, 'minute'))
  }


  /**
   * Updates the value for this component by saving its child inputs to core component.
   * @return {boolean} - Success indicator
   */
  updateValue () {
    if (this.input.hour.value.length === 0 && this.input.minute.value.length === 0) {
      this.value = ''
    } else {
      this.value = `${this.input.hour.value}:${this.input.minute.value}`
    }
    this.element.value = this.value
    this.element.dispatchEvent(new Event('change'))
    return true
  }


  /**
   * Sets the value for this component by splitting a time formated string.
   * @param {string} newValue - A valid time string with format "dd:mm"
   * @return {boolean} - Success indicator
   */
  setValue (newValue) {
    if (typeof newValue !== 'string' && newValue !== null) {
      console.error('The newValue for inputTime.setValue needs to be of type string!')
      return false
    }
    const regEx = new RegExp(/^([\d]{1,2})\:([\d]{1,2})/g).exec(newValue)
    this.input.hour.value = regEx !== null ? regEx[1] || null : null
    this.input.minute.value = regEx!== null ? regEx[2] || null : null
    return this.updateValue()
  }


  /**
   * Handles business logic for the inputs keydown event.
   * @param {object} event - The event that was triggered for the input
   * @param {string} inputType - The type of input that was triggered: 'hour', 'minute'
   */
  keydownHandler (event, inputType) {
    if (this.numberKeys.includes(event.keyCode) || this.editKeys.includes(event.keyCode)) {
      const hasSelection = !(event.target.selectionStart == event.target.selectionEnd)
      const minValue = inputType === 'hour' ? this.options.minHourValue : this.options.minMinuteValue
      const maxValue = inputType === 'hour' ? this.options.maxHourValue : this.options.maxMinuteValue
      const maxFirstChar = parseInt(maxValue.toString().substring(0, 1))

      // NUMBER keys
      if (this.numberKeys.includes(event.keyCode)) {
        // When nothing is selected
        if (!hasSelection) {
          // Skip entry when length is already 2
          if (event.target.value.length >= 2) {
            event.stopPropagation()
            event.preventDefault()
            return
          }
        }
        // Check if the input is really a number
        if (!RegExp(/^[0-9]$/i).test(event.key) || event.keyCode === 229) {
          event.stopPropagation()
          event.preventDefault()
          return
        }
        // Add leading 0 to values that can only be single chars
        if (event.target.value.length < 1 && parseInt(event.key) > maxFirstChar) {
          event.stopPropagation()
          event.preventDefault()
          event.target.value = `0${event.key}`
          event.target.dispatchEvent(new Event('change'))
        }

      } else if (event.keyCode === 38) {
        // UP-key: Increase value by 1 on press of
        let value = event.target.value.length > 0 ? parseInt(event.target.value) : minValue - 1
        event.target.value = ++value <= maxValue ? value : maxValue
        event.target.dispatchEvent(new Event('change'))

      } else if (event.keyCode === 40) {
        // DOWN-key: Decrease value by 1 on press of
        let value = event.target.value.length > 0 ? parseInt(event.target.value) : minValue
        event.target.value = --value > (minValue - 1) ? value : minValue
        event.target.dispatchEvent(new Event('change'))

      } else if (inputType === 'hour' && event.keyCode === 39 && !hasSelection) {
        // RIGHT-key: focus next field if on end of selection
        if (event.target.selectionEnd === event.target.value.length) {
          event.preventDefault()
          this.input.minute.focus()
          this.input.minute.setSelectionRange(0, 0)
        }
      } else if (inputType === 'minute' && event.keyCode === 37 && !hasSelection) {
        // LEFT-key: focus previous field if on beginning of selection
        if (event.target.selectionEnd === 0) {
          event.preventDefault()
          this.input.hour.focus()
          this.input.hour.setSelectionRange(this.input.hour.value.length, this.input.hour.value.length)
        }
      } else if (inputType === 'minute' && event.keyCode === 8 && !hasSelection) {
        // BACKSPACE-key: focus and delete previous field number
        if (event.target.selectionEnd === 0) {
          this.input.hour.focus()
          this.input.hour.setSelectionRange(this.input.hour.value.length, this.input.hour.value.length)
        }
      }
      return
    } else {
      event.stopPropagation()
      event.preventDefault()
      return
    }
  }


  /**
   * Handles business logic for the inputs keyup event.
   * @param {object} event - The event that was triggered for the input
   * @param {string} inputType - The type of input that was triggered: 'hour', 'minute'
   */
  keyupHandler (event, inputType) {
    // ALL Keys
    if (this.numberKeys.includes(event.keyCode) || this.editKeys.includes(event.keyCode)) {
      const minValue = inputType === 'hour' ? this.options.minHourValue : this.options.minMinuteValue
      const maxValue = inputType === 'hour' ? this.options.maxHourValue : this.options.maxMinuteValue
      // Limit value to max
      if (parseInt(event.target.value) > maxValue) {
        event.target.value = maxValue
        event.target.dispatchEvent(new Event('change'))
      }
      // Limit value to min
      if (parseInt(event.target.value) < minValue) {
        event.target.value = minValue
        event.target.dispatchEvent(new Event('change'))
      }
    }
    // NUMBER Keys
    if (this.numberKeys.includes(event.keyCode)) {
      // Jump to next input when length >= 2
      if (inputType === 'hour' && event.target.value.length >= 2) {
        this.input.minute.focus()
        this.input.minute.setSelectionRange(0, this.input.minute.value.length)
      }
    }
  }


  /**
   * Handles business logic for the input's change event.
   * @param {object} event - The event that was triggered for the input
   * @param {string} inputType - The type of input that was triggered: 'hour', 'minute'
   */
  changeHandler (event, inputType) {
    // Add a leading 0 for single chars
    if (event.target.value.length === 1) {
      event.target.value = `0${event.target.value}`
    }
    this.updateValue()
  }


  /**
   * Handles business logic for the input's focus event.
   * @param {object} event - The event that was triggered for the input
   * @param {string} inputType - The type of input that was triggered: 'hour', 'minute'
   */
  focusHandler (event, inputType) {
    if (event.target.value.length > 0) {
      event.target.setSelectionRange(0, event.target.value.length)
    }
  }
}
