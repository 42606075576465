import { parseHtml } from '../../helper/html'


/**
 * The parent component default options.
 */
const defaultOptions = {
  attrName: '',
  template: `<div class="editList-item" data-edit-list-item></div>`,
}


export default class EditListItem {
  constructor (parent, userOptions) {
    this.options = {...defaultOptions, ...userOptions}
    this.parent = parent
    this.element = null
    this.index = null
    this.type = null
    return this
  }

  
  /**
   * Initializes the basic editListItem.
   * @param {number} itemIndex - The index of the item wihtin the parent list
   * @param {*} itemValue - (optional) The value(s) for this item
   * @param {HTMLElement} itemElement - (optional) A valid HTMLElement for the module
   */
  initBasicItem (itemIndex, itemValue = null, itemElement = null) {
    this.element = itemElement || parseHtml(this.options.template)[0]
    this.setIndex(itemIndex)
    this.init()
    return this
  }

  
  /**
   * Placeholder method for child module initialization.
   */
  init () {
    console.log('(Parent) INIT ITEM:', this.index)
  }


  /**
   * Sets the index of this item.
   * @param {number} itemIndex - The new index for this item
   * @return {boolean}
   */
  setIndex (itemIndex) {
    if (typeof itemIndex !== 'number') {
      console.error('Cannot set index for EditListItem. It requires a valid number passed!')
      return false
    }
    this.index = itemIndex
    this.element.setAttribute('data-edit-list-item', this.index)
    return true
  }


  /**
   * Sets the value of this item.
   * @param {string} itemValue - The new value of this item
   * @return {boolean}
   */
  setValue (itemValue) {
    this.value = itemValue
    return true
  }


  /**
   * Sets focus to this item.
   */
  focus () {
    setTimeout(() => { this.input.focus() }, 0)
    return this
  }

  
  /**
   * Destroy this EditListItem.
   */
  destroy () {
    this.parent.removeItem(this)
  }
}