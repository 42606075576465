import NotifierService from '../Notifier/NotifierService'

/**
 * Singleton storage.
 */
let instance = null

/**
 * Singleton export.
 */
export default (userOptions) => {
  if (instance !== null) {
    return instance
  }
  return instance = new ApiErrorService (userOptions)
}


let options = {
  template: `
    <div>
      <h3 class="mb-2">[title]</h3>
      <p>[body]</p>
    </div>
  `,
  errorMessages: [
    {
      code: 'default',
      title: 'Es ist ein Fehler aufgetreten!',
      body: 'Bitte versuche es erneut. Sollte der Fehler weiterhin auftreten, bitte kontaktiere das Team Clubhouse. Vielen Dank!',
    },
    {
      code: 400,
      title: 'Es ist ein Fehler aufgetreten!',
      body: 'Bitte versuche es erneut. Sollte der Fehler weiterhin auftreten, bitte kontaktiere das Team Clubhouse. Vielen Dank!',
    },
    {
      code: 422,
      title: 'Die Daten sind ungültig!',
      body: 'Bitte überprüfe deine Daten und probiere es erneut.',
    },
    {
      code: 500,
      title: 'Es ist ein Fehler aufgetreten!',
      body: 'Bitte versuche es erneut. Sollte der Fehler weiterhin auftreten, bitte kontaktiere das Team Clubhouse. Vielen Dank!',
    },
  ],
}


class ApiErrorService {
  /**
   * The ApiErrorService handles errors thrown by endpoints via status codes.
   * @param {object} userOptions - The Configuration for this service
   * @return {ApiErrorService}
   */
  constructor (userOptions) {
    options = {...options, ...userOptions}
    this.instances = {}
    return this
  }

  
  /**
   * Initializes the service.
   */
  init () {
    return this
  }


  /**
   * Handles the error by constructing and displaying an error message.
   * @param {object} - A valid XHR response object
   */
  handleError (error) {
    let messageElement = null
    if (detectMessage(error.response)) {
      messageElement = this.getMessage(detectMessage(error.response))
    } else {
      console.log('error.response.code', error.response.status)
      messageElement = this.getMessage(error.response.status)
    }
    NotifierService().createNotification(messageElement, 'danger')
    console.error('response error', error.response)
  }


  /**
   * Constructs an error message element using a status code or default.
   * @param {number} statusCode - The status code of the response header
   * @return {HTMLElement} - The error message as HTMLElement
   */
  getMessage (statusCode = 'default', message = null) {
    console.log('statusCode', statusCode)
    let errorMessage = options.errorMessages.filter((errorMessage) => errorMessage.code === statusCode)
    if (errorMessage.length === 0) {
      errorMessage = options.errorMessages.filter((errorMessage) => errorMessage.code === 'default')
    }
    errorMessage = errorMessage[0]
    let messageElement = null
    let template = options.template
    if (errorMessage) {
      template = template.replace('[title]', errorMessage.title)
      template = template.replace('[body]', message || errorMessage.body)
    }
    return template
  }
}


/**
 * Tries to extract an error message from the response object.
 * @param {object} response - A XHR response object
 * @return {string|boolean} - The message or false
 */
function detectMessage (response) {
  if (response.hasOwnProperty('data')) {
    if (typeof response.data === 'string') {
      return response.data
    }
  }
  return false
}