import EditListItem from './editList-item'
import { parseHtml } from '../../helper/html'
import InputTime from '../inputTime/inputTime'

// TODO:
// * Add Keyboard + Mouse input behavior for daypicker (SHIFT+click)
// * Optimize options setup (flatten)


/**
 * The Components default options.
 */
const defaultOptions = {
  attrName: 'name',
  btnGroup: {
    template: `<button type="button" tabindex="-1">{{itemName}}</button>`,
    active: 'active', // Class that will be de/attached if the btn is in/active
    // TODO: Rename to ~ day-picker-data
    items: [
      { name: 'Mo.', value: 'mon', handle: 'toggle' },
      { name: 'Di.', value: 'tue', handle: 'toggle' },
      { name: 'Mi.', value: 'wed', handle: 'toggle' },
      { name: 'Do.', value: 'thu', handle: 'toggle' },
      { name: 'Fr.', value: 'fri', handle: 'toggle' },
      { name: 'Sa.', value: 'sat', handle: 'toggle' },
      { name: 'So.', value: 'sun', handle: 'toggle' },
    ],
  },
  template: `
    <div class="editList-item editList-item-datetime" data-edit-list-item data-edit-list-item-type="datetime">
      <input type="hidden" data-edit-list-datetime-output />
      <div class="editList-btnGroup mr-1" data-edit-list-item-area="btn-group" role="group"></div>
      <div class="editList-timeInputs">
        <div class="input-time" data-input-time></div>
        <div class="d-flex align-items-center mx-1 font-weight-bold">-</div>
        <div class="input-time" data-input-time></div>
      </div>
      <div class="editList-item-panel">
        <svg data-edit-list-btn="remove">
          <use xlink:href="#icon-close" />
        </svg>
      </div>
    </div>
  `
}


export default class EditListDatetimeItem extends EditListItem {
  constructor (parent, userOptions) {
    super(parent, {...defaultOptions, ...userOptions})
    this.type = 'datetime'
    return this
  }

  
  /**
   * Initializes the Module.
   */
  init () {
    const inputTimes = this.element.querySelectorAll('[data-input-time]')
    this.input = {
      json: '',
      days: [],
      start: new InputTime(inputTimes[0]).init(),
      stop:  new InputTime(inputTimes[1]).init(),
    }
    // Init output
    this.output = this.element.querySelector('[data-edit-list-datetime-output]')
    this.output.setAttribute('name',this.options.attrName)
    this.selection = [] // stores the ids of editlist-items
    this.initInputs()
    this.initBtns()
    this.initBtnRemove()
    this.input.start.element.addEventListener('change', event => this.renderOutput())
    this.input.stop.element.addEventListener('change', event => this.renderOutput())
    return this
  }


  /**
   * Initializes additional input handlers.
   */
  initInputs () {
    // ENTER: jumps to next input field
    function handleEnterFocusStop (event, Component) {
      if (event.keyCode === 13) {
        event.preventDefault()
        Component.input.stop.input.hour.focus()
        Component.input.stop.input.hour.setSelectionRange(0, Component.input.stop.input.hour.value.length)
      }
    }
    // ENTER: creates new line
    function handleEnterNewItem (event, Component) {
      if (event.keyCode === 13) {
        event.preventDefault()
        const newItem = Component.parent.addItem(Component.index + 1)
        Component.parent.focusItem(newItem.index)
      }
    }
    // RIGHT: jumps to next field
    function handleRightFocusStop (event, Component) {
      if (event.keyCode === 39 && event.target.selectionStart === 2) {
        event.preventDefault()
        Component.input.stop.input.hour.focus()
        Component.input.stop.input.hour.setSelectionRange(0,0)
      }
    }
    // LEFT: jumps to previous field
    function handleLeftFocusStart (event, Component) {
      if (event.keyCode === 37 && event.target.selectionEnd === 0) {
        event.preventDefault()
        Component.input.start.input.minute.focus()
        const inputLength = Component.input.start.input.minute.value.length
        Component.input.start.input.minute.setSelectionRange(inputLength, inputLength)
      }
    }
    // ANY: Focus next input if info complete
    function handleKeyUpFocusNext (event, Component) {
      if (event.target.value.length >= 2 && RegExp(/^[0-9]$/i).test(event.key)) {
        Component.input.stop.input.hour.focus()
        Component.input.stop.input.hour.setSelectionRange(0, Component.input.stop.input.hour.value.length)
      }
    }
    // ADD Listeners
    this.input.start.input.hour.addEventListener('keydown', event => handleEnterFocusStop(event, this))
    this.input.start.input.minute.addEventListener('keydown', event => {
      handleEnterFocusStop(event, this)
      handleRightFocusStop(event, this)
    })
    this.input.start.input.minute.addEventListener('keyup', event => handleKeyUpFocusNext(event, this))
    this.input.stop.input.hour.addEventListener('keydown', event => {
      handleEnterNewItem(event, this)
      handleLeftFocusStart(event, this)
    })
    this.input.stop.input.minute.addEventListener('keydown', event => {
      handleEnterNewItem(event, this)
    })
  }


  /**
   * Initializes the daypicker button group.
   */
  initBtns () {
    const btnGroup = this.element.querySelector('[data-edit-list-item-area="btn-group"]')
    this.options.btnGroup.items
      .forEach((btnGroupItem) => {
        // Construct and append btn item ...
        let itemElement = parseHtml(this.options.btnGroup.template.replace(/{{[\s]*itemName[\s]*}}/g, btnGroupItem.name))[0]
        if (btnGroupItem.handle === 'toggle') {
          itemElement.addEventListener('click', () => {
            event.preventDefault() // Stop the event bubbling
            this.toggleDay(parseInt(event.target.getAttribute('data-btn-index')))
          })
        }
        itemElement.setAttribute('data-btn-index', this.input.days.push(itemElement) - 1)
        itemElement.value = btnGroupItem.value
        btnGroup.appendChild(itemElement)
      })
  }


  /**
   * Initialize the remove button.
   */
  initBtnRemove () {
    this.element
      .querySelector('[data-edit-list-btn="remove"]')
      .addEventListener('click', (event) => this.parent.removeItem(this))
  }


  /**
   * Toggles a day selection.
   * @param {number} dayIndex - The index of the toggled day
   * @return {boolean} - Success indicator
   */
  toggleDay (dayIndex) {
    if (this.selection.filter(index => index === dayIndex).length > 0) {
      return this.unselectDay(dayIndex)
    } else {
      return this.selectDay(dayIndex)
    }
  }
  
  
  /**
   * Selects a day.
   * @param {number} dayIndex - The index of the selected day
   * @return {boolean} - Success indicator
   */
  selectDay (dayIndex) {
    if (this.selection.filter(index => index === dayIndex).length > 0) {
      return false
    }
    this.input.days[dayIndex].classList.add(this.options.btnGroup.active)
    this.selection.push(dayIndex)
    this.renderOutput()
    return true
  }

  
  /**
   * Unelects a day.
   * @param {number} dayIndex - The index of the unselected day
   * @return {boolean} - Success indicator
   */
  unselectDay (dayIndex) {
    if (this.selection.filter(index => index === dayIndex).length === 0) {
      return false
    }
    this.input.days[dayIndex].classList.remove(this.options.btnGroup.active)
    this.selection = this.selection.filter(index => index != dayIndex)
    this.renderOutput()
    return true
  }


  /**
   * Outputs data of this module into its input field as stringified json value
   * '{"weekdays":["mo","mi"], "start": "9:00", "stop": "17:00"}'
   */
  renderOutput () {
    let outputData = { 
      weekdays: this.options.btnGroup.items
        .filter((item, index) => this.selection.includes(index))
        .map(item => item.value),
      start: this.input.start.element.value || null, 
      stop:  this.input.stop.element.value || null
    }
    this.output.value = JSON.stringify(outputData)
    this.parent.element.dispatchEvent(new CustomEvent('item:change'))
  }


  /**
   * Sets the value of this item.
   * @param {string|object} itemValue - The new value of this item
   * @return {boolean}
   */
  setValue (itemValue) {
    if (typeof itemValue === 'string') {
      itemValue = JSON.parse(itemValue)
    }
    if (typeof itemValue === 'object') {
      if (itemValue.hasOwnProperty('weekdays')) {
        itemValue.weekdays.forEach(weekday => {
          const weekdayIndex = this.options.btnGroup.items.findIndex(weekdayItem => weekdayItem.value === weekday)
          this.selectDay (weekdayIndex)
        })
      }
      if (itemValue.hasOwnProperty('start')) {
        this.input.start.setValue(itemValue.start.length > 0 ? itemValue.start : null)
      }
      if (itemValue.hasOwnProperty('stop')) {
        this.input.stop.setValue(itemValue.stop.length > 0 ? itemValue.stop : null)
      }
      return true
    } else {
      return false
    }
  }
  

  /**
   * Sets focus to this item.
   */
  focus () {
    this.input.start.input.hour.focus()
    return this
  }
}